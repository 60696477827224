import http from '@/libs/http'
export default class SociosService {
  getSocios (payload = {}) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios`, {
      params: { ...payload }
    })
  }

  findSocio (id) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios/${id}`)
  }

  getAll () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios/all`)
  }

  saveSocio (payload = {}) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios`, payload)
  }

  saveEstadosFinancieros (payload = {}) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios-estados-financieros`, payload)
  }

  updateSocio (id, payload = {}) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios/${id}`, payload)
  }

  removeSocio (id) {
    return http.delete(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios/${id}`)
  }

  getAllEstadosFinancieros (payload = {}) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios-estados-financieros/last-year/${payload.id}`)
  }

  updateEstadoFinanciero (id, payload = {}) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios-estados-financieros/${id}`, payload)
  }

  findParametros () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios-estados-financieros/find-parametros`)
  }

  getWithNit (nit = 900249425, anio = null) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/socios/nit/${nit}`, {
      params: { anio },
      headers: {
        loading: !!anio
      }
    })
  }
}
