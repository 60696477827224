<template>
  <div class="box mb-4 p-2 mt-2">
    <Header/>
    <Acordeon/>
  </div>
</template>

<script>
import Header from './components/Header'
import Acordeon from './components/Acordeon'
export default {
  name: 'HomeEstadosFinancieros',
  components: {
    Header,
    Acordeon
  }
}
</script>

<style scoped>

</style>
