<template>
  <div class="flex gap-2">
    <Button label="Atras"
            icon="pi pi-arrow-left"
            class="p-button-danger"
            @click="$router.go(-1)"
    />
    <Button label="Nuevo"
            icon="pi pi-plus"
            @click="openNew"
    />
    <div class="flex items-center">
      <span class="font-bold text-lg">Socio: <span class="font-normal">{{Socio.nombre}}</span></span>
    </div>
  </div>

  <Dialog v-model:visible="display" :style="{width: '450px'}" header="Nuevo año" :modal="true" class="p-fluid">
    <div class="field">
      <label for="anios">Nombre</label>
      <Dropdown id="anios" v-model="EstadoFinanciero.anio" :options="aniosList" optionLabel="value" placeholder="Seleccione un año" />
    </div>
    <div class="field" v-for="(item,index) in parametros" :key="index">
      <label :for="item.nombre">{{ item.nombre }}</label>
      <InputNumber :id="item.nombre"
                 v-model="EstadoFinanciero[item.code]" mode="currency" currency="COP" locale="es-CO"
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="openNew"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class=""
        @click="saveModal"
      />
    </template>
  </Dialog>
  <Toast/>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import useSocios from '../../../hooks/useSocios'
import { useToast } from 'primevue/usetoast'
import { useRoute } from 'vue-router'

export default {
  name: 'EstadosFinancierosHeader',
  setup () {
    const display = ref(false)
    const route = useRoute()
    const { findSocio, getParametros, saveEstadosFinancieros } = useSocios()
    const Socio = ref({
      nombre: '',
      nit: ''
    })
    const EstadoFinanciero = ref({
      anio: 2018
    })
    const toast = useToast()
    const aniosList = computed(() => {
      const anios = []
      for (let i = 2018; i < 2100; i++) {
        anios.push({
          value: i
        })
      }
      return anios
    })
    const parametros = ref([])
    const getParametrosList = async () => {
      parametros.value = await getParametros()
      parametros.value.map(item => {
        EstadoFinanciero.value[item.code] = null
      })
    }

    onMounted(() => {
      asignSocio()
      getParametrosList()
    })

    const asignSocio = async () => {
      const socio = await findSocio(route.params.id)
      Socio.value = {
        nombre: socio.nombre,
        nit: socio.nit
      }
    }

    const openNew = () => {
      display.value = !display.value
    }

    const saveModal = async () => {
      for (const [index] of Object.entries(EstadoFinanciero.value)) {
        if (index === 0) {
          return toast.add({ severity: 'warn', summary: 'Advertencia', detail: `El campo ${index === 'anio' ? 'año' : index} esta vacio`, life: 3000 })
        }
      }
      display.value = !display.value
      EstadoFinanciero.value.idSocio = route.params.id
      await saveEstadosFinancieros({ ...EstadoFinanciero.value })
      parametros.value.map(item => {
        EstadoFinanciero.value[item.code] = null
      })
      EstadoFinanciero.value.anio = null
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Socio creado correctamente', life: 3000 })
    }

    return {
      openNew,
      display,
      Socio,
      saveModal,
      EstadoFinanciero,
      aniosList,
      parametros
    }
  }
}
</script>

<style scoped>

</style>
