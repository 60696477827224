<template>
  <Message severity="info" :closable="false">Seleccione el valor que desea modificar y presione la tecla "Enter" para modificar o la tecla "Esc" para cancelar.</Message>
  <Accordion>
    <AccordionTab v-for="(item,index) in getStoreEstadosFinancieros" :header="index" :key="index">
      <DataTable :value="item" editMode="cell" @cell-edit-complete="onCellEditComplete" @cell-edit-init="onCellEditInit" @cell-edit-cancel="onCellEditCancel" class="editable-cells-table" responsiveLayout="scroll">
        <Column field="vlr" header="Valor" style="width:33%">
          <template #body="{data}">
            {{$h.formatCurrency(data.vlr)}}
          </template>
          <template #editor="{ data }">
            <InputText v-model="data.vlr" autofocus />
          </template>
        </Column>
        <Column field="code" header="Codigo" style="width:33%">
          <template #body="{data}">
            {{data.parametro.nombre}}
          </template>
        </Column>
        <Column field="ano" header="Año" style="width:33%"></Column>
      </DataTable>
    </AccordionTab>
  </Accordion>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import useSocios from '@/apps/pharmasan/licitaciones/hooks/useSocios'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'EstadosFinancierosAcordeon',
  setup () {
    const store = useStore()
    const route = useRoute()
    const toast = useToast()
    const getStoreEstadosFinancieros = computed(() => store.getters['storeLicitaciones/getStoreEstadosFinancieros'])
    const { getEstadosFinancieros, updateEstadoFinanciero } = useSocios()
    const oldValue = ref(null)

    onMounted(async () => {
      await getEstadosFinancieros({
        id: route.params.id
      })
      // console.log(getStoreEstadosFinancieros.value[2017])
    })

    const onCellEditComplete = async (e) => {
      if (e.field === 'vlr' && (e.data.vlr !== oldValue.value)) {
        await updateEstadoFinanciero(e.data.id, {
          vlr: e.data.vlr
        }, route.params.id)
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Valor actualizado correctamente', life: 3000 })
      }
    }

    const onCellEditCancel = async (e) => {
      if (e.field === 'vlr') {
        e.data.vlr = oldValue.value
      }
    }

    const onCellEditInit = async (e) => {
      if (e.field === 'vlr') {
        oldValue.value = e.data.vlr
      }
    }

    return {
      getStoreEstadosFinancieros,
      onCellEditComplete,
      onCellEditCancel,
      onCellEditInit
    }
  }
}
</script>

<style scoped>

</style>
