import SociosService from '../services/SociosService'
import { useStore } from 'vuex'
import lodash from 'lodash'

const useSocios = () => {
  const store = useStore()
  const sociosService = new SociosService()

  const anios = () => {
    const anios = []
    for (let i = 2018; i < 2100; i++) {
      anios.push({
        value: i
      })
    }
    return anios
  }

  const getSocios = async (payload = {}) => {
    try {
      const { data } = await sociosService.getSocios(payload)
      store.dispatch('storeLicitaciones/searchSocios', data)
      return true
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  const findSocio = async (id) => {
    try {
      const { data } = await sociosService.findSocio(id)
      return data
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  const getEstadosFinancieros = async (payload = {}) => {
    try {
      const { data } = await sociosService.getAllEstadosFinancieros(payload)
      let newdata = lodash.orderBy(data.rows, 'code', 'asc')
      newdata = lodash.groupBy(newdata, 'ano')
      store.dispatch('storeLicitaciones/searchEstadosFinancieros', newdata)
      return true
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  const getParametros = async () => {
    try {
      const { data } = await sociosService.findParametros()
      return data
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  const saveSocio = async (payload = {}) => {
    try {
      await sociosService.saveSocio(payload)
      const { data } = await sociosService.getSocios(payload)
      store.dispatch('storeLicitaciones/searchSocios', data)
      return true
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  const saveEstadosFinancieros = async (payload = {}) => {
    try {
      await sociosService.saveEstadosFinancieros(payload)
      getEstadosFinancieros({ id: payload.idSocio })
      return true
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  const updateSocio = async (id, payload = {}) => {
    try {
      await sociosService.updateSocio(id, payload)
      const { data } = await sociosService.getSocios(payload)
      store.dispatch('storeLicitaciones/searchSocios', data)
      return true
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  const updateEstadoFinanciero = async (id, payload = {}, idSocio) => {
    try {
      await sociosService.updateEstadoFinanciero(id, payload)
      getEstadosFinancieros({ id: idSocio })
      return true
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  const removeSocio = async (id) => {
    try {
      await sociosService.removeSocio(id)
      const { data } = await sociosService.getSocios()
      store.dispatch('storeLicitaciones/searchSocios', data)
      return true
    } catch (error) {
      console.error('error getIncomes', error)
      throw error
    }
  }

  return {
    getSocios,
    saveSocio,
    updateSocio,
    removeSocio,
    getEstadosFinancieros,
    findSocio,
    updateEstadoFinanciero,
    anios,
    getParametros,
    saveEstadosFinancieros
  }
}

export default useSocios
